import { DeleteOutline } from "@mui/icons-material";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import Alert from "components/alert";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { knowledgeBulkAction } from "../api/knowledge";

const BulkActions = ({
  selectedRows,
  setSelectedRows,
  setReload,
  permissions,
}) => {
  const [alert, setAlert] = useState({ status: false, data: null });

  const dispatch = useDispatch();
  const handleBulkAction = async (type, data) => {
    setAlert({ status: false, data: null });
    dispatch(setLoading(true));
    const obj = { selected_ids: selectedRows, action: type };
    obj[type] = data;
    try {
      await knowledgeBulkAction({ body_data: obj });
    } finally {
      dispatch(setLoading(false));
      setSelectedRows([]);
      setReload((pre) => !pre);
    }
  };

  return (
    <Box
      p="10px 20px 10px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "18px",
        backgroundColor: "#e3eefa",
      }}
    >
      {alert?.status && (
        <Alert
          open={alert?.status}
          handleClose={() => setAlert({ status: false, data: null })}
          title="Delete Selected Lead"
          desc="Are you sure you want to delete? Don't worry you will find these leads on Trash Menu"
          buttons={
            <>
              <Button
                onClick={() => {
                  setAlert({ status: false, data: null });
                  setSelectedRows([]);
                }}
              >
                No, keep it
              </Button>
              <Button
                variant="solid"
                onClick={() => handleBulkAction(alert.data)}
              >
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Typography variant="h5">{selectedRows?.length} Selected</Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <TextField
          sx={{ width: "180px" }}
          size="small"
          label="Change status"
          select
          onChange={(e) => {
            handleBulkAction("status", e.target.value);
          }}
        >
          {["Inactive", "Active"]?.length ? (
            ["Inactive", "Active"]?.map((status, i) => (
              <MenuItem value={i}>{status}</MenuItem>
            ))
          ) : (
            <MenuItem disabled>No status available</MenuItem>
          )}
        </TextField>
        {!!permissions?.delete && (
          <Button
            variant="solid"
            onClick={() => setAlert({ status: true, data: "delete" })}
            startIcon={<DeleteOutline />}
          >
            Delete
          </Button>
        )}
        {!!permissions?.delete && (
          <Button
            variant="solid"
            onClick={() => setAlert({ status: true, data: "delete-all" })}
            startIcon={<DeleteOutline />}
          >
            Delete All
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BulkActions;
