import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import ActivityHeader from "./ActivityHeader";
import BulkActions from "./BulkActions";
import Filter from "./Filter";
import MainTable from "./table/MainTable";

const ActivityLog = () => {
  const { base } = useBase();
  const { loading, fetchData } = useApi();
  const [activityLogs, setActivityLogs] = useState([]);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    const getActivityList = async () => {
      const endpoint = {
        method: "post",
        url:
          "/api/admin/activity-log/list?" +
          `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
        data: finalFilters,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data, ...rest } = result?.data;
        setActivityLogs(data);
        setPagination(rest);
      }
    };
    getActivityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const endpoint = {
      method: "delete",
      url: `/api/admin/activity-log/delete/${openAlert}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      if (activityLogs?.length > 1) {
        setActivityLogs(activityLogs.filter((aclg) => aclg?.id !== openAlert));
      }
    } else {
      setReload((pre) => !pre);
    }
    setSelectedRows([]);
  };

  const permissions = usePermissions("activity-log");

  // Bulk options
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(activityLogs?.map((al) => al?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((al) => al !== id));
      }
    }
  };

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Log"
          desc={
            <Box>
              Are you sure you want to delete this Log? This will delete the log
              permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <ActivityHeader
        {...{
          activityLogs,
          setSearch,
          setPagination,
          pagination,
          setFilters,
          filters,
          isFiltering,
          permissions,
          ...base,
        }}
      />

      {!selectedRows?.length > 0 &&
        !!(permissions["list(own)"] || permissions["list(global)"]) && (
          <Filter
            {...{
              setPagination,
              pagination,
              setFilters,
              filters,
              isFiltering,
              setReload,
              ...base,
            }}
          />
        )}
      {selectedRows?.length > 0 && (
        <BulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setReload={setReload}
          permissions={permissions}
        />
      )}
      {permissions["list(own)"] || permissions["list(global)"] ? (
        <MainTable
          activityLogs={activityLogs}
          handleDelete={handleDelete}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          setReload={setReload}
          selectedRows={selectedRows}
          handleTableCheckBox={handleTableCheckBox}
          permissions={permissions}
        />
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default ActivityLog;
