import { Box, Button, Skeleton, Typography } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import {
  viewInvoices,
  viewProjectCustomerInvoices,
} from "features/projectCustomers/api/projectCustomer";
import useApiCall from "hooks/useApiCall";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddInvoice from "./AddInvoice";
import InvoiceTable from "./InvoiceTable";
import MakePayment from "./MakePayment";
import ViewInvoice from "./viewInvoice/ViewInvoice";
import { AiOutlineProject } from "react-icons/ai";
import InvoiceSummary from "./InvoiceSummary";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

const Invoice = ({
  customer_id,
  project_id,
  reference_id,
  user_id,
  assignees = [],
  intake_id = "",
  module_name,
  handleDone = () => {},
}) => {
  let user_role = useSelector((state) => state?.auth?.user_details?.role);

  const [addInvoice, setAddInvoice] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [currInvoice, setCurrInvoice] = useState({});
  const [allInvoice, setAllInvoice] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: module_name === "project-customer" ? 100 : 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    let finalPagination = { ...pagination };
    let fetchApi =
      module_name === "project-customer"
        ? viewProjectCustomerInvoices
        : viewInvoices;
    fetchListData(
      fetchApi,
      {
        body_data: {
          customer_id: customer_id ? [customer_id] : [],
          project_id:
            user_role === "Admin" ? [] : project_id ? [project_id] : [],
          user_id: user_id ? [user_id] : [],
        },
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`,
      },
      false
    );
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllInvoice(allData);
      setPagination(rest);
    }
  }, [data]);

  const allInvoiceGroupByProject = allInvoice.reduce((acc, curr) => {
    const project_name = curr?.project_name?.toLowerCase() || "no project";
    if (!acc[project_name]) {
      acc[project_name] = [];
    }
    acc[project_name].push(curr);
    return acc;
  }, {});

  return addInvoice ? (
    <AddInvoice
      setAddInvoice={setAddInvoice}
      setReload={setReload}
      currInvoice={currInvoice}
      customer_id={customer_id}
      project_id={project_id}
      reference_id={reference_id}
      user_id={user_id}
      defaultAssignees={assignees}
      defaultIntakeId={intake_id}
      handleDone={() => {
        allInvoice?.length === 0 && handleDone();
      }}
    />
  ) : makePayment ? (
    <MakePayment
      setMakePayment={setMakePayment}
      currInvoice={currInvoice}
      setReload={setReload}
    />
  ) : viewInvoice ? (
    <ViewInvoice invoice_id={viewInvoice} setViewInvoice={setViewInvoice} />
  ) : (
    <Box sx={{ m: "-20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      >
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in Invoice.."}
        />
        <Button
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrInvoice({});
            setAddInvoice(true);
          }}
        >
          New Invoice
        </Button>
      </Box>

      {module_name === "project-customer" && (
        <InvoiceSummary customer_id={customer_id} project_id={project_id} />
      )}

      {loading && (
        <Box
          sx={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ReactLoading
            type="spin"
            color="#181921"
            height="50px"
            width="50px"
          />
        </Box>
      )}

      {!loading && module_name !== "project-customer" ? (
        <InvoiceTable
          loading={loading}
          allInvoice={allInvoice}
          pagination={pagination}
          setPagination={setPagination}
          setReload={setReload}
          setAddInvoice={setAddInvoice}
          setCurrInvoice={setCurrInvoice}
          setAllInvoice={setAllInvoice}
          setMakePayment={setMakePayment}
          setViewInvoice={setViewInvoice}
          module_name={module_name}
          handleDone={() => {
            handleDone();
          }}
        />
      ) : !loading && Object.keys(allInvoiceGroupByProject).length > 0 ? (
        Object.keys(allInvoiceGroupByProject).map((project_name) => (
          <Box key={project_name} mb="60px">
            <Box pl="15px" display="flex" alignItems="center">
              <AiOutlineProject size={18} />
              <Typography fontWeight={500} component={"span"} fontSize={18}>
                Project:
              </Typography>
              <Typography
                component={"span"}
                ml={1}
                textTransform="uppercase"
                fontSize={18}
                color="#666"
              >
                {project_name || "-"}
              </Typography>
            </Box>
            <InvoiceTable
              loading={loading}
              allInvoice={allInvoiceGroupByProject[project_name]}
              pagination={pagination}
              setPagination={setPagination}
              setReload={setReload}
              setAddInvoice={setAddInvoice}
              setCurrInvoice={setCurrInvoice}
              setAllInvoice={setAllInvoice}
              setMakePayment={setMakePayment}
              setViewInvoice={setViewInvoice}
              module_name={module_name}
              handleDone={() => {
                handleDone();
              }}
            />
          </Box>
        ))
      ) : (
        <>
          {!loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Typography>No Invoice Found</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Invoice;
