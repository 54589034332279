import { DeleteOutline, DownloadOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import Alert from "components/alert";
import useApi from "hooks/useApi";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

const BulkActions = ({
  selectedRows,
  setSelectedRows,
  setReload,
  permissions,
}) => {
  const [alert, setAlert] = useState({ status: false, data: null });

  const { fetchData } = useApi();

  const dispatch = useDispatch();
  const handleBulkAction = async (type, data) => {
    setAlert({ status: false, data: null });
    dispatch(setLoading(true));
    const obj = { selected_ids: selectedRows, action: type };
    if (type === "export") obj.trash_export = false;
    obj[type] = data;
    try {
      const endpoint = {
        method: "post",
        url: "/api/admin/activity-log/bulk/action",
        data: obj,
      };
      const result = await fetchData(endpoint);
      if (result?.data?.file) window.open(result?.data?.file);
    } finally {
      setSelectedRows([]);
      dispatch(setLoading(false));
      setReload((pre) => !pre);
    }
  };

  return (
    <Box
      p="10px 20px 10px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "18px",
        backgroundColor: "#e3eefa",
      }}
    >
      {alert?.status && (
        <Alert
          open={alert?.status}
          handleClose={() => setAlert({ status: false, data: null })}
          title="Delete Selected Logs"
          desc={
            <Box>
              Are you sure you want to delete this Log? This will delete the log
              permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button
                onClick={() => {
                  setAlert({ status: false, data: null });
                  setSelectedRows([]);
                }}
              >
                No, keep it
              </Button>
              <Button
                variant="solid"
                onClick={() => handleBulkAction(alert.data)}
              >
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Typography variant="h5">{selectedRows?.length} Selected</Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        {!!permissions?.delete && (
          <Button
            variant="solid"
            onClick={() => setAlert({ status: true, data: "delete" })}
            startIcon={<DeleteOutline />}
          >
            Delete
          </Button>
        )}
        {!!permissions?.export && (
          <Button
            variant="solid"
            onClick={() => handleBulkAction("export")}
            startIcon={<DownloadOutlined />}
          >
            Export
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BulkActions;
