import { Box, Button, Typography } from "@mui/material";
import { setLoading } from "lib/Reducer/loadingSlice";
import { CgTrashEmpty } from "react-icons/cg";
import { MdRestore } from "react-icons/md";
import { useDispatch } from "react-redux";
import { leadBulkAction } from "../api/leads";

const LeadsBulkActions = ({ selectedRows, setReload, permissions }) => {
  const dispatch = useDispatch();
  const handleBulkAction = async (type, data) => {
    dispatch(setLoading(true));
    const obj = {
      selected_ids: selectedRows,
      action: type,
      ...(type === "export" && { trash_export: true }),
    };
    obj[type] = data;
    try {
      const result = await leadBulkAction({ body_data: obj });
      if (result?.data?.file) window.open(result?.data?.file);
    } finally {
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    }
  };

  return (
    <Box
      p="10px 20px 10px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "18px",
        backgroundColor: "#e3eefa",
      }}
    >
      <Typography variant="h5">{selectedRows?.length} Selected</Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        {!!permissions?.delete && (
          <Button
            variant="solid"
            onClick={() => handleBulkAction("delete")}
            startIcon={<CgTrashEmpty />}
          >
            Delete Selected
          </Button>
        )}
        {!!permissions?.restore && (
          <Button
            variant="solid"
            onClick={() => handleBulkAction("restore")}
            startIcon={<MdRestore />}
          >
            Restore Selected
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default LeadsBulkActions;
