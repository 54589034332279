import { Box, Button, Typography } from "@mui/material";
import Alert from "components/alert";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { CgTrashEmpty } from "react-icons/cg";
import { MdRestore } from "react-icons/md";
import { useDispatch } from "react-redux";
import { invoiceBulkAction } from "../api/invoices";

const BulkActions = ({
  selectedRows,
  setSelectedRows,
  setReload,
  permissions,
}) => {
  const [alert, setAlert] = useState({ status: false, data: null });

  const dispatch = useDispatch();
  const handleBulkAction = async (type, data) => {
    setAlert({ status: false, data: null });
    dispatch(setLoading(true));
    const obj = { selected_ids: selectedRows, action: type };
    obj[type] = data;
    try {
      const result = await invoiceBulkAction({ body_data: obj });
      if (result?.data?.file) window.open(result?.data?.file);
    } finally {
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    }
  };

  return (
    <Box
      p="10px 20px 10.60px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "18px",
        backgroundColor: "#e3eefa",
      }}
    >
      {alert?.status && (
        <Alert
          open={alert?.status}
          handleClose={() => setAlert({ status: false, data: null })}
          title="Delete Selected Invoice"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this invoice
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button
                onClick={() => {
                  setAlert({ status: false, data: null });
                  setSelectedRows([]);
                }}
              >
                No, keep it
              </Button>
              <Button
                variant="solid"
                onClick={() => handleBulkAction(alert.data)}
              >
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Typography variant="h5">{selectedRows?.length} Selected</Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        {!!permissions?.delete && (
          <Button
            variant="solid"
            onClick={() => setAlert({ status: true, data: "delete" })}
            startIcon={<CgTrashEmpty />}
          >
            Delete Selected
          </Button>
        )}
        {!!permissions?.restore && (
          <Button
            variant="solid"
            onClick={() => handleBulkAction("restore")}
            startIcon={<MdRestore />}
          >
            Restore Selected
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BulkActions;
