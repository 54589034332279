import Axios from "lib/Axios";
import * as yup from "yup";

const validationSchema = (preEmail, preNumber) =>
  yup.object({
    status: yup.string("").required("Status is Required").nullable(),
    source_id: yup.string("").required("Source id is Required").nullable(),
    assignees: yup.array().required("Assignee is Required").min(1),
    courses: yup
      .array()
      .of(
        yup.object().shape({
          course_id: yup.string("").required("Course is Required"),
        })
      )
      .required()
      .min(1),

    name: yup.string("").required("Name is Required").nullable(),
    phone: yup
      .string()
      .required("Phone number is required")
      .test(
        "phone-number-exists",
        "Phone number already exists",
        async function (value) {
          try {
            if (value?.length && value !== preNumber) {
              const response = await Axios.post("/api/admin/lead/check-phone", {
                phone: value,
              });
              if (response.data.code === 200) {
                return false;
              }
              return true;
            } else {
              return true;
            }
          } catch (error) {
            return true;
          }
        }
      )
      .nullable(),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email address is required")
      .test(
        "email-exists",
        "Email address already exists",
        async function (value) {
          try {
            if (value?.length && value !== preEmail) {
              const response = await Axios.post("/api/admin/lead/check-phone", {
                email: value,
              });
              if (response.data.code === 200) {
                return false;
              }
              return true;
            } else {
              return true;
            }
          } catch (error) {
            return true;
          }
        }
      )
      .nullable(),
    country_id: yup.string("").required("Country is Required").nullable(),
    previous_refused: yup.string().required("Required Field"),
    jobs: yup
      .array()
      .of(
        yup.object().shape({
          company_name: yup.string("").required("Company name is Required"),
          title: yup.string("").required("Job title is Required"),
        })
      )
      .required("Invoice is Required"),
    counsilor: yup.string("").required("Counsilor is Required"),
  });

export default validationSchema;
