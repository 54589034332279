import Profile from "components/profile/Profile";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import Axios from "lib/Axios";
import { saveAttStatus } from "lib/Reducer/attendanceSlice";
import { logout } from "lib/Reducer/authSlice";
import { saveBase } from "lib/Reducer/baseSlice";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  RiFullscreenLine,
  RiLoginCircleLine,
  RiLogoutCircleLine,
  RiUser6Line,
} from "react-icons/ri";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import toggleFullscreen from "utils/toggleFullscreen";
import DateTime from "./DateTime";
// MUI COMPONENTS
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// MUI ICONS
import Logout from "@mui/icons-material/Logout";
import Refresh from "@mui/icons-material/Refresh";
import useWindowSize from "hooks/useWindowSize";
import { FcOvertime } from "react-icons/fc";
import { FiMenu } from "react-icons/fi";
import { MdOutlineSecurity } from "react-icons/md";
import ChatList from "./ChatList";
import Notification from "./Notification";
import { setLoading } from "lib/Reducer/loadingSlice";
import HeaderTodo from "./HeaderTodo";
import { VscSignIn, VscSignOut } from "react-icons/vsc";

const AppBar = ({ toggleNavbar, setToogleNavbar, isDashboardPath }) => {
  const user_details = useSelector((state) => state.auth.user_details);
  const { isMini, width } = useWindowSize();
  const user = user_details?.user;
  const dispatch = useDispatch();
  let location = useLocation();
  let path = location.pathname.split("/")[1];
  const [currentTitle, setCurrentTitle] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [indexToOpen, setIndexToOpen] = useState(null);

  const formatTitle = (title) => {
    const makeCapital = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };
    let modifiedTitle = "";
    title
      ?.split("-")
      .map((st) =>
        modifiedTitle
          ? (modifiedTitle += " " + makeCapital(st))
          : (modifiedTitle += makeCapital(st))
      );
    return modifiedTitle;
  };

  useEffect(() => {
    if (path) {
      const pathArray = location.pathname.split("/").slice(1);
      let title = "";
      pathArray.map((p) =>
        title
          ? (title += " > " + formatTitle(p))
          : (title += " " + formatTitle(p))
      );
      setCurrentTitle(title);
    } else {
      setCurrentTitle("Dashboard");
    }
  }, [location?.key]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const auth = useSelector((state) => state.auth);

  const handleLogout = async () => {
    dispatch(setLoading(true));
    try {
      if (auth?.user_details?.role !== "Customer") {
        await Axios.post("/api/admin/logout");
      } else {
        await Axios.post("/api/logout");
      }

      dispatch(logout());
    } catch (error) {
      toast.error("Something went wrong!");
      dispatch(logout());
    }
    dispatch(setLoading(false));
  };

  const { loading, fetchData } = useApi();
  const permissions = usePermissions("attendance");
  const attStatus = useSelector((state) => state?.attendance?.status); // 1==="Not checked In", 2==="Checked In", 3==="Checked Out"
  const attendance_id = useSelector(
    (state) => state?.attendance?.attendance_id
  );

  const handleCheckin = async () => {
    const endpoint = {
      method: "post",
      url: "/api/admin/attendance/checkin",
      data: {
        date: moment().format("YYYY-MM-DD"),
        start_time: moment().format("HH:mm:ss"),
        user_id: user?.id,
      },
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      dispatch(saveAttStatus({ status: 2, attendance_id: result?.data?.id }));
    }
  };

  const handleCheckOut = async () => {
    const endpoint = {
      method: "get",
      url: `/api/admin/attendance/checkout/${attendance_id}`,
    };
    const result = await fetchData(endpoint);
    result.success &&
      dispatch(saveAttStatus({ status: 3, attendance_id: null }));
  };

  useEffect(() => {
    const getAttendanceStatus = async () => {
      const endpoint = (id) => ({
        method: "get",
        url: `/api/admin/base/today-attendance-status/${id}`,
      });
      const result = await fetchData(endpoint(user?.id), false);
      if (result.success) {
        if (!result?.data) {
          dispatch(saveAttStatus({ status: 1, attendance_id: null }));
        } else if (result?.data?.start_time && !result?.data?.end_time) {
          dispatch(
            saveAttStatus({ status: 2, attendance_id: result?.data?.id })
          );
        } else if (result?.data?.start_time && result?.data?.end_time)
          dispatch(saveAttStatus({ status: 3, attendance_id: null }));
      }
    };
    getAttendanceStatus();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "65px",
        bgcolor: (theme) => theme.palette.white.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: isMini ? "0 10px 0 20px" : "0 20px",
      }}
    >
      <Box
        onClick={() => setToogleNavbar((pre) => !pre)}
        sx={{
          height: "33px",
          width: "33px",
          color: (theme) => theme.palette.primary.main,
          cursor: "pointer",
          borderRadius: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "26px",
          mr: "15px",
        }}
      >
        <FiMenu />
      </Box>
      {width > 700 && (
        <Box
          sx={{
            mr: "auto",
          }}
        >
          <Typography variant="h4">{currentTitle}</Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {loading ? (
          <IconButton disabled={loading}>
            <ReactLoading
              type="spinningBubbles"
              color="#000"
              height="25px"
              width="25px"
            />
          </IconButton>
        ) : attStatus === 1 ? (
          <Button
            sx={{ mr: "10px" }}
            disabled={!permissions?.checkin}
            variant="text"
            startIcon={<VscSignIn />}
            onClick={handleCheckin}
          >
            Check In
          </Button>
        ) : attStatus === 2 ? (
          <Button
            sx={{ mr: "10px", color: "#f72323" }}
            disabled={!permissions?.checkin}
            variant="text"
            startIcon={<VscSignOut />}
            onClick={handleCheckOut}
          >
            Check Out
          </Button>
        ) : (
          <></>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Tooltip title="Full Screen">
            <IconButton onClick={toggleFullscreen}>
              <RiFullscreenLine />
            </IconButton>
          </Tooltip>
          <HeaderTodo />
          <ChatList />
          <Notification />
        </Box>
        {!isMini && <DateTime />}
        <IconButton
          onClick={handleClick}
          size="small"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            sx={{ cursor: "pointer" }}
            src={UNIVERSAL.BASEURL + user?.image}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              setIndexToOpen("1");
              setOpenProfile(true);
            }}
          >
            <ListItemIcon>
              <RiUser6Line fontSize={20} />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setIndexToOpen("2");
              setOpenProfile(true);
            }}
          >
            <ListItemIcon>
              <MdOutlineSecurity fontSize={20} />
            </ListItemIcon>
            Permissions
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setIndexToOpen("3");
              setOpenProfile(true);
            }}
          >
            <ListItemIcon>
              <FcOvertime fontSize={20} />
            </ListItemIcon>
            My Timesheet
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              dispatch(saveBase({ data: null, updated: true }));
            }}
          >
            <ListItemIcon>
              <Refresh fontSize="small" />
            </ListItemIcon>
            Reload Setup
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        {openProfile && (
          <Profile
            openDrawer={openProfile}
            setOpenDrawer={setOpenProfile}
            index_to_open={indexToOpen}
          />
        )}
      </Box>
    </Box>
  );
};

export default AppBar;
